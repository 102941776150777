import { render, staticRenderFns } from "./categoryCard.vue?vue&type=template&id=899144e6&scoped=true&"
import script from "./categoryCard.vue?vue&type=script&lang=js&"
export * from "./categoryCard.vue?vue&type=script&lang=js&"
import style0 from "./categoryCard.vue?vue&type=style&index=0&id=899144e6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "899144e6",
  null
  
)

export default component.exports